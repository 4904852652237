import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import * as serviceWorker from './serviceWorker'

console.log(
  `TrackOps version: ${process.env.REACT_APP_GIT_SHA} (https://github.mlbam.net/bdata/trackops/commit/${process.env.REACT_APP_GIT_SHA})`
)

fetch('/api/info')
  .then(r => r.json())
  .then(info => Object.assign(window, info))
  .then(() => import('./App'))
  .then(({ default: App }) => {
    ReactDOM.render(<App />, document.getElementById('root'))
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
